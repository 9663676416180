<template>
  <div class="mobile-container">
    <div class="page-header-container">
      <div class="page-title">首页</div>
      <div class="search-container">
        <div class="search-item">
          <div class="icon img-cover"><img src="../../assets/images/search-icon.png" /></div>
          <div class="input-item"><input ref="searchInput" v-model="searchKey" placeholder="输入要搜索的内容" type="search" @keyup.enter="handleSearch" /></div>
          <div class="btn" @click="handleSearch">搜索</div>
        </div>
      </div>
      <div class="banner-container">
        <div class="banner-item">
          <van-swipe :autoplay="4500" indicator-color="white">
            <van-swipe-item v-for="(item, index) in bannerList" :key="index">
              <div class="img img-cover" @click="bannerJump(item)">
                <img :src="item.Img" />
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
        <router-link v-if="ggList && ggList.length > 0" class="gg-item" :to="{ path: '/gg' }">
          <div class="icon img-cover"><img src="../../assets/images/gg-icon.png" /></div>
          <van-swipe v-if="ggList && ggList.length > 0" class="detail-item" vertical :autoplay="5000" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in ggList" :key="index" @click="handleShowGg(item)">
              <div class="item">{{ item.Title }}</div>
            </van-swipe-item>
          </van-swipe>
          <div class="right img-cover"><img src="../../assets/images/arrow-right-icon.png" /></div>
        </router-link>
      </div>
    </div>
    <div class="ability-list-item">
      <router-link class="item" :to="{path: '/watermark'}">
        <div class="icon img-contain"><img src="../../assets/images/home-nav-icon-1.png" /></div>
        <div class="name">去水印</div>
      </router-link>
      <router-link class="item" :to="{path: '/goodsIsBo', query: {id: isBoGoodsId}}">
        <div class="icon img-contain"><img src="../../assets/images/home-nav-icon-2.png" /></div>
        <div class="name">流量领取</div>
      </router-link>
      <router-link class="item" :to="{path: '/vip'}">
        <div class="icon img-contain"><img src="../../assets/images/home-nav-icon-3.png" /></div>
        <div class="name">升级会员</div>
      </router-link>
      <router-link class="item" :to="{path: '/contact'}">
        <div class="icon img-contain"><img src="../../assets/images/home-nav-icon-4.png" /></div>
        <div class="name">联系客服</div>
      </router-link>
    </div>
    <div class="page-content-item">
      <div v-if="classList.length > 0" class="class-list-item">
        <van-tabs v-model="selectedClassId[0]" :ellipsis="false" :swipe-threshold="0" @change="handleSelectedClassChange">
          <van-tab :name="index" :title="item.Name" v-for="(item, index) in classList" :key="index" />
        </van-tabs>
      </div>
      <div v-if="selectedClassId[0] >= 0 && classList[selectedClassId[0]].Childern && classList[selectedClassId[0]].Childern.length > 0" class="class-child-list-item">
        <van-tabs v-model="selectedClassId[1]" type="card" :ellipsis="false" :swipe-threshold="0" @change="handleSelectedClassChildChange">
          <van-tab :name="index" :title="item.Name" v-for="(item, index) in classList[selectedClassId[0]].Childern" :key="index" />
        </van-tabs>
      </div>
      <div v-if="selectedClassId[1] >= 0 && classList[selectedClassId[0]].Childern && classList[selectedClassId[0]].Childern[selectedClassId[1]].Childern && classList[selectedClassId[0]].Childern[selectedClassId[1]].Childern.length > 0" class="class-child-list-item">
        <van-tabs v-model="selectedClassId[2]" type="card" :ellipsis="false" :swipe-threshold="0" @change="handleSelectedClassChildrenChange">
          <van-tab :name="index" :title="item.Name" v-for="(item, index) in classList[selectedClassId[0]].Childern[selectedClassId[1]].Childern" :key="index" />
        </van-tabs>
      </div>
      <div class="sort-list-item">
        <div class="sort-item" @click="handleChangeSort('Wcsj')">
          <div class="name">完成时间</div>
          <div class="arrow">
            <div class="img img-cover">
              <img v-if="sort.Wcsj == 2" src="../../assets/images/arrow-icon-blue.png" />
              <img v-else src="../../assets/images/arrow-icon.png" />
            </div>
            <div class="img down img-cover">
              <img v-if="sort.Wcsj == 1" src="../../assets/images/arrow-icon-blue.png" />
              <img v-else src="../../assets/images/arrow-icon.png" />
            </div>
          </div>
        </div>
        <div class="sort-item" @click="handleChangeSort('Wcl')">
          <div class="name">完成率</div>
          <div class="arrow">
            <div class="img img-cover">
              <img v-if="sort.Wcl == 2" src="../../assets/images/arrow-icon-blue.png" />
              <img v-else src="../../assets/images/arrow-icon.png" />
            </div>
            <div class="img down img-cover">
              <img v-if="sort.Wcl == 1" src="../../assets/images/arrow-icon-blue.png" />
              <img v-else src="../../assets/images/arrow-icon.png" />
            </div>
          </div>
        </div>
        <div class="sort-item" @click="handleChangeSort('Jg')">
          <div class="name">订单价格</div>
          <div class="arrow">
            <div class="img img-cover">
              <img v-if="sort.Jg == 2" src="../../assets/images/arrow-icon-blue.png" />
              <img v-else src="../../assets/images/arrow-icon.png" />
            </div>
            <div class="img down img-cover">
              <img v-if="sort.Jg == 1" src="../../assets/images/arrow-icon-blue.png" />
              <img v-else src="../../assets/images/arrow-icon.png" />
            </div>
          </div>
        </div>
      </div>
      <van-list v-model="loading" :finished="finish" @load="getGoodsList" :immediate-check="false" loading-text="加载中" finished-text="" error-text="加载失败">
        <div v-if="finish && !loading && goodsList.length == 0" class="empty-item" style="padding-top: .5rem">
          <div class="icon img-cover"><img src="../../assets/images/empty-icon.png" /></div>
        </div>
        <div v-else class="goods-list">
          <template v-for="(item, index) in goodsList">
            <router-link v-if="item.IsClose == 2" :to="{path: '/goodsDetail', query: {id: item.Id}}" class="goods" :key="'2_' + index">
              <div class="detail">
                <div class="img img-cover">
                  <img v-if="item.GoodsThumb" :src="item.GoodsThumb" />
                  <img v-else src="../../assets/images/no-image-icon.png" />
                </div>
                <div class="info">
                  <div class="name"><span v-if="item.Lable">{{ item.Lable }}</span> {{ item.GoodsName }}</div>
                  <div class="money">¥ <span>{{ item.GoodsPrice }}</span></div>
                  <div class="btn public-btn">
                    去推广
                    <img src="../../assets/images/arrow-right-icon-white.png" />
                  </div>
                </div>
              </div>
              <div class="count-item">
                <div class="count">完成率：<span>{{ item.Wcl }}%</span></div>
                <div class="count">平均完成时间：<span>{{ item.Pjwcsj | formatPjwcsj }}h</span></div>
                <div class="count">下单数量：<span>{{ item.BuyMinLimit }}-{{ item.BuyMaxLimit }}</span></div>
              </div>
            </router-link>
            <div v-else class="goods" :key="'1_' + index">
              <div class="detail">
                <div class="img img-cover">
                  <img v-if="item.GoodsThumb" :src="item.GoodsThumb" />
                  <img v-else src="../../assets/images/no-image-icon.png" />
                </div>
                <div class="info">
                  <div class="name"><span v-if="item.Lable">{{ item.Lable }}</span> {{ item.GoodsName }}</div>
                  <div class="money">¥ <span>{{ item.GoodsPrice }}</span></div>
                  <div class="btn public-not-btn">
                    暂停购买
                    <img src="../../assets/images/arrow-right-icon-white.png" />
                  </div>
                </div>
              </div>
              <div class="count-item">
                <div class="count">完成率：{{ item.Wcl }}%</div>
                <div class="count">平均完成时间：{{ item.Pjwcsj | formatPjwcsj }}h</div>
                <div class="count">下单数量：{{ item.BuyMinLimit }}-{{ item.BuyMaxLimit }}</div>
              </div>
            </div>
          </template>
        </div>
      </van-list>
    </div>
    <mobileTabBar :tabBarKey="tabBarKey" />
    <mobilePopupComponent v-if="showMobilePopup" ref="mobilePopup" @onSure="mobilePopupOnSure" />
    <template v-for="(item, index) in ggList">
      <van-dialog v-if="item.IsTan == 1" :key="index" v-model="item.show" :show-cancel-button="false" :show-confirm-button="false">
        <div class="gg-popup-container">
          <div class="title-item img-cover">
            <img src="../../assets/images/pop-head-bg.png" />
            <div class="title">{{ item.Title }}</div>
          </div>
          <div class="content-item"><div v-html="item.Content"></div></div>
          <div class="btn-item">
            <div class="btn sure" @click="item.show = false">我知道了</div>
          </div>
        </div>
      </van-dialog>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileTabBar from '@/components/mobileTabBar'
import mobilePopupComponent from '@/components/mobilePopupComponent'

export default {
  name: "Home",
  components: {
    mobileTabBar,
    mobilePopupComponent
  },
  data() {
    return {
      tabBarKey: 'home',
      showMobilePopup: false,
      isBoGoodsId: 0,
      bannerList: [],
      classList: [],
      selectedClassId: [],
      sort: {
        Wcsj: 0,
        Wcl: 0,
        Jg: 0
      },
      goodsList: [],
      pageCount: 10,
      page: 1,
      finish: false,
      loading: false,
      searchKey: '',
      goodsName: '',
      showNoticRed: false,
      ggList: [],
      showSstz: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    searchKey(val){
      if (val == '' && this.goodsName != '') {
        this.goodsName = ''
        this.finish = false
        this.loading = false
        this.page = 1
        this.goodsList = []
        // this.getGoodsList()
      }
    }
  },
  filters: {
    formatPjwcsj(val) {
      if (val) {
        return Math.round(parseInt(val) / 3600)
      }
      return 0
    }
  },
  mounted() {
    this.getBannerData()
    this.getClassList()
    // if (this.userInfo.Id) {
    //   this.handleShowNoticRed()
    // }
    this.handleGetIsBoGoods()
    this.getGgList()
    this.handleGetSetting()
  },
  methods: {
    async handleGetIsBoGoods() {
      const { websiteInfo } = this
      var res = await this.$API.goodsList.post({
        IsBo: 1,
        AdminId: websiteInfo.Id
      })
      if (res.info && res.info.length > 0) {
        this.isBoGoodsId = res.info[0].Id
      }
    },
    async handleGetSetting() {
      var res = await this.$API.getSet.post({
        'Key': 'cz_show'
      })
      const { userInfo } = this
      if (userInfo && userInfo.Id) {
        if (userInfo.UserIsSs == 1 && parseFloat(userInfo.AllMoney) >= parseFloat(res.info)) {
          this.showSstz = true
        } else {
          this.showSstz = false
        }
      } else {
        if (res.info == -1) {
          this.showSstz = true
        } else {
          this.showSstz = false
        }
      }
    },
    handleShowGg(data) {
      this.showMobilePopup = true
      this.$nextTick(() => {
        this.$refs.mobilePopup.open({
          title: data.Title,
          tips: '',
          content: data.Content,
          sureText: '我知道了',
          showCancel: false
        })
      })
    },
    mobilePopupOnSure() {
      this.showMobilePopup = false
    },
    async getGgList() {
      var res = await this.$API.announcedList.post();
      if (res && res.info && res.info.length > 0) {
        const data = []
        for (let index = 0; index < res.info.length; index++) {
          const item = res.info[index]
          data.push({
            ...item,
            show: true
          })
        }
        this.ggList = data
      }
    },
    async handleShowNoticRed() {
      var res = await this.$API.notifyRed.post()
      this.showNoticRed = res.info == 1
    },
    handleSearch() {
      if (this.searchKey) {
        this.goodsName = this.searchKey
        this.finish = false
        this.loading = false
        this.page = 1
        this.goodsList = []
        // this.getGoodsList()
        this.$refs.searchInput.blur()
      }
    },
    async getBannerData() {
      var res = await this.$API.bannerList.post();
      this.bannerList = res.info || []
    },
    async getClassList() {
      var res = await this.$API.goodsCategoryList.post({
        IsAll: 1
      });
      this.classList = res.info || []
      this.handleSelectedClassChange(0)
      this.getGoodsList()
    },
    handleSelectedClassChange(value) {
      const { classList } = this
      const list = [value]
      if (classList[value] && classList[value].Childern && classList[value].Childern.length > 0) {
        list.push(0)
        if (classList[value].Childern[0].Childern && classList[value].Childern[0].Childern.length > 0) {
          list.push(0)
        }
      }
      this.selectedClassId = list
      this.finish = false
      this.loading = false
      this.page = 1
      this.goodsList = []
    },
    handleSelectedClassChildChange(value) {
      const { classList, selectedClassId } = this
      const list = [selectedClassId[0], value]
      if (classList[selectedClassId[0]].Childern[value].Childern && classList[selectedClassId[0]].Childern[value].Childern.length > 0) {
        list.push(0)
      }
      this.selectedClassId = list
      this.finish = false
      this.loading = false
      this.page = 1
      this.goodsList = []
    },
    handleSelectedClassChildrenChange(value) {
      const { selectedClassId } = this
      const list = [selectedClassId[0], selectedClassId[1], value]
      this.selectedClassId = list
      this.finish = false
      this.loading = false
      this.page = 1
      this.goodsList = []
    },
    handleChangeSort(key) {
      const { sort } = this
      this.sort[key] = sort[key] == 1 ? 2 : 1
      this.finish = false
      this.loading = false
      this.page = 1
      this.goodsList = []
    },
    async getGoodsList() {
      const { userInfo } = this
      if (userInfo && userInfo.Id && userInfo.UserIsGoods != 1) {
        this.finish = true
        this.loading = false
        return
      }
      const { pageCount, page, goodsList, goodsName, websiteInfo, classList, selectedClassId, sort } = this
      const params = {
        PageCount: pageCount, 
        Page: page,
        AdminId: websiteInfo.Id,
        Wcsj: sort.Wcsj,
        Wcl: sort.Wcl,
        Jg: sort.Jg
      }
      if (goodsName) {
        params.GoodsName = goodsName
      } else {
        params.IsIndex = 1
      }
      if (selectedClassId[0] >= 0) {
        params.CategoryId = classList[selectedClassId[0]].Id
      }
      if (selectedClassId[1] >= 0) {
        params.CategoryId2 = classList[selectedClassId[0]].Childern[selectedClassId[1]].Id
      }
      if (selectedClassId[2] >= 0) {
        params.CategoryId3 = classList[selectedClassId[0]].Childern[selectedClassId[1]].Childern[selectedClassId[2]].Id
      }
      var res = await this.$API.goodsList.post(params)
      this.goodsList = [
        ...goodsList,
        ...res.info
      ]
      this.page = page + 1
      this.finish = res.info.length < pageCount
      this.loading = false
    },
    async handleCollection(item, index) {
      var res = await this.$API.addGoodsCollect.post({
        GoodsId: item.Id
      })
      if (res.error == 0) {
        this.goodsList[index].IsCollect = item.IsCollect > 0 ? 0 : 1
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
      return false
    },
    bannerJump(item) {
      if (item.ParentId && item.Type == 1) {
        this.$router.push({
          path: '/goodsDetail',
          query: {
            id: item.ParentId
          }
        })
      }
      if (item.ParentId && item.Type == 2) {
        window.location.href = item.ParentId
      }
    }
  }
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
}
.page-header-container {
  width: 100%;
  // height: 5.2rem;
  background: linear-gradient( 180deg, #bce6fe 0%, rgba(188, 230, 254, 0) 100%);
  .page-title {
    width: 100%;
    height: .88rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #000;
    font-size: .32rem;
  }
  .search-container {
    padding: .08rem .28rem .24rem .36rem;
    .search-item {
      height: .72rem;
      border-radius: .16rem;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 .08rem 0 .16rem;
      .icon {
        width: .4rem;
        height: .4rem;
      }
      .input-item {
        padding: 0 .16rem;
        flex: 1;
      }
      .btn {
        cursor: pointer;
        width: 1.2rem;
        height: .56rem;
        background-color: #276eff;
        border-radius: .12rem;
        box-shadow: 0 0 .12rem 0 rgba(160, 195, 124, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .24rem;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .banner-container {
    /* height: 2.74rem; */
    margin: 0 .32rem;
    background-color: #fff;
    border-radius: .24rem;
    overflow: hidden;
    .banner-item {
      width: 6.86rem;
      height: 2.4rem;
      border-radius: .24rem;
      overflow: hidden;
      .img {
        width: 6.86rem;
        height: 2.4rem;
        border-radius: .24rem;
        overflow: hidden;
      }
      /deep/ .van-swipe__indicator {
        width: .1rem;
        height: .1rem;
        border-radius: 50%;
      }
    }
    .gg-item {
      padding: 0 .2rem;
      height: .8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        width: .48rem;
        height: .48rem;
      }
      .detail-item {
        flex: 1;
        height: .6rem;
        margin-left: .12rem;
        .item {
          height: .6rem;
          line-height: .6rem;
          text-align: left;
          font-size: .24rem;
          color: #b3b3b3;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .right {
        margin-left: .12rem;
        width: .32rem;
        height: .32rem;
      }
    }
  }
}
.ability-list-item {
  padding: .24rem .32rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 1.44rem;
    height: 1.36rem;
    font-size: .24rem;
    color: #151d2b;
    .icon {
      width: .88rem;
      height: .88rem;
      overflow: hidden;
    }
    .name {
      line-height: .36rem;
    }
  }
}
.message-item {
  width: 6.86rem;
  height: .72rem;
  margin: 0 .32rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: .16rem;
  background-color: rgba(88, 183, 255, .16);
  .icon {
    width: .64rem;
    height: .64rem;
    margin-left: .08rem;
  }
  .detail-item {
    flex: 1;
    height: .72rem;
    color: #58b7ff;
    font-size: .24rem;
    margin: 0 .2rem;
    .item {
      width: 100%;
      height: .72rem;
      line-height: .72rem;
    }
  }
}
.page-content-item {
  background-color: #fff;
  border-radius: .24rem .24rem 0 0;
  overflow: hidden;
  /deep/ .van-tabs__wrap--scrollable .cover {
    display: none;
  }
  .class-list-item {
    width: 100%;
    height: .8rem;
    border-bottom: .02rem solid #f7f7f7;
    /deep/ .van-tabs__nav--line {
      padding: 0 .18rem 15px;
    }
    /deep/ .van-tab {
      padding: 0 .22rem;
      font-size: .3rem;
      color: #b3b3b3;
      &.van-tab--active{
        color: #276eff;
        font-weight: bold;
      }
    }
    /deep/ .van-tabs--line .van-tabs__wrap {
      height: .8rem;
    }
    /deep/ .van-tabs__line {
      background-color: #276eff;
    }
  }
  .class-child-list-item {
    padding: .2rem 0 0;
    /deep/ .van-tabs__nav--card {
      border: none;
      margin: 0;
    }
    /deep/.van-tabs__nav--card {
      .van-tab,
      .van-tab__single {
        height: .6rem;
        line-height: .6rem;
        padding: 0 .24rem;
        border-radius: .12rem;
        background-color: #f7f7f8;
        color: #4d4d4d;
        font-weight: 500;
        font-size: .24rem;
        border: none;
        margin-right: .24rem;
        margin-left: 0;
        &.van-tab--active,
        &.van-tab__single--active {
          background-color: #276eff;
          color: #fff;
        }
      }
      .van-tab__gap {
        display: none;
      }
      .van-tab--complete {
        flex: none;
      }
    }
  }
  .sort-list-item {
    padding: .2rem .2rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .sort-item {
      cursor: pointer;
      height: .6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: .32rem;
      padding: 0 .24rem;
      .name {
        color: #999;
        font-size: .24rem;
        font-weight: 500;
      }
      .arrow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 .04rem 0 .08rem;
        .img {
          width: .16rem;
          height: .12rem;
          &.down {
            transform: rotate(180deg);
            margin-top: -.02rem;
          }
        }
      }
    }
  }
  .goods-list {
    padding: .16rem .32rem;
    .goods {
      display: block;
      padding: .2rem;
      box-shadow: 0 .04rem .16rem 0 rgba(39, 110, 255, .1);
      border-radius: .16rem;
      background-color: #fff;
      margin-bottom: .24rem;
      .detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img {
          width: 2.08rem;
          height: 2.08rem;
          border-radius: .16rem;
          overflow: hidden;
        }
        .info {
          position: relative;
          width: 4.14rem;
          height: 2.08rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .name {
            margin-top: .12rem;
            line-height: .48rem;
            color: #2b2b2b;
            font-size: .28rem;
            height: .96rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              height: .36rem;
              line-height: .36rem;
              padding: 0 .12rem;
              margin-right: .1rem;
              margin-top: .04rem;
              font-size: .2rem;
              color: #fff;
              border-radius: .04rem;
              background: linear-gradient( 315deg, #ffaa8a 0%, #ffde58 100%);
            }
          }
          .money {
            color: #ff2442;
            font-size: .24rem;
            font-weight: bold;
            margin-bottom: .14rem;
            span {
              font-size: .36rem;
            }
          }
          .btn {
            position: absolute;
            right: -.28rem;
            bottom: .04rem;
            width: 1.32rem;
            padding-left: .14rem;
            height: .52rem;
            border-radius: .26rem 0 0 .26rem;
            background-color: #276eff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .24rem;
            font-weight: 500;
            color: #fff;
            img {
              width: .28rem;
              height: .28rem;
              margin-left: .04rem;
            }
          }
        }
      }
      .count-item {
        margin-top: .16rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .count {
          padding: .05rem .2rem;
          line-height: .3rem;
          background-color: rgba(39, 110, 255, .08);
          border-radius: .08rem;
          color: #276eff;
          font-size: .2rem;
          &:nth-child(2) {
            margin: 0 .1rem;
          }
          span {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.gg-popup-container {
  .title-item {
    width: 100%;
    height: 1.2rem;
    position: relative;
    .title {
      position: absolute;
      top: .48rem;
      width: 100%;
      text-align: center;
      line-height: .48rem;
      color: #333;
      font-size: .32rem;
      font-weight: bold;
    }
  }
  .content-item {
    margin: .24rem .32rem;
    min-height: 1.84rem;
    overflow-y: auto;
    line-height: .48rem;
    color: #333;
    font-size: .28rem;
  }
  .btn-item {
    padding: 0 .32rem .32rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      cursor: pointer;
      flex: 1;
      height: .8rem;
      color: #fff;
      text-align: center;
      line-height: .8rem;
      font-size: .28rem;
      font-weight: bold;
      border-radius: .12rem;
      background: linear-gradient( 135deg, #58b7ff 0%, #276eff 100%);
    }
  }
}
</style>
